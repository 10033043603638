<script setup>
import { computed, onUpdated, ref, watch, watchEffect } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { useUpdateNote } from '@/queries/notes/useUpdateNote';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  asset: {
    required: true,
  },
  isAutosave: {
    required: false,
    default: true,
  },
  label: {
    required: false,
    type: String,
    default: 'add editing notes',
  },
});

const emits = defineEmits(['is-notes-dirty']);
const queryClient = useQueryClient();

const asset = computed(() => props.asset);
const assetNote = computed(() => asset.value?.note?.content);

const noteIdRef = ref(null);
const noteId = computed(() => noteIdRef.value || asset.value.note?.id);
const assetId = computed(() => asset.value?.id);
const isAutosave = computed(() => props.isAutosave);
const label = computed(() => props.label);
const currentNote = ref('');
const textareaRef = ref(null);
const textareaHasFocus = ref(false);
const isHelperActive = ref(false);
const isInitialized = ref(false);
const isEditing = ref(false);
const id = uniqueId('asset-notes-');

const { start: hideHelper } = useTimeoutFn(
  () => (isHelperActive.value = false),
  5000,
  { immediate: false }
);

const {
  mutate: createDigitalAssetNote,
  isPending: digitalAssetNotePending,
  error: createNoteError,
} = useCreateNote('digital_assets', assetId);

const {
  mutate: updateNote,
  isPending: isUpdating,
  error: updateNoteError,
} = useUpdateNote('digital_assets', assetId);

const isError = computed(() => createNoteError.value || updateNoteError.value);

const isLoading = computed(() => isUpdating.value);

const helperCopy = computed(() => {
  if (isLoading.value) return 'saving…';
  if (isError.value)
    return 'our tiny robots are having problems. unable to save.';
  return 'our tiny robots saved your notes';
});

const helperIcon = computed(() => {
  if (isError.value) return 'triangle-exclamation-solid';
  return 'circle-check-solid';
});

// methods
const changeInputHeight = (el, offsetHeight, scrollHeight) => {
  if (scrollHeight > offsetHeight) {
    el.style.height = `${scrollHeight + 10}px`;
  }
};

const handleSuccess = data => {
  if (!noteId.value) {
    // c2c-anytime isn't refreshing notedata fast enough to prevent double saves
    // so store it in a ref until cache is busted
    noteIdRef.value = data.find(note => !!note.id)?.id;
  }
  if (isAutosave.value) hideHelper();
  isEditing.value = false;
};

const saveDigitalAssetNote = () => {
  if (noteId.value) {
    updateNote(
      { noteId: noteId.value, content: textareaRef.value?.value },
      {
        onSuccess: async data => {
          await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
          handleSuccess(data);
        },
      }
    );
  } else {
    createDigitalAssetNote(
      {
        content: textareaRef.value?.value,
      },
      {
        onSuccess: data => handleSuccess(data),
      }
    );
  }
};

const handleSave = async () => {
  if (assetNote.value === textareaRef.value?.value) {
    isEditing.value = false;
    return;
  }

  if (digitalAssetNotePending.value) {
    return;
  }

  if (isAutosave.value) isHelperActive.value = true;

  saveDigitalAssetNote();
};

const debouncedSave = computed(() => {
  return debounce(() => {
    handleSave();
  }, 2000);
});

const handleInput = e => {
  const offsetHeight = e.target.offsetHeight;
  const scrollHeight = e.target.scrollHeight;

  changeInputHeight(e.target, offsetHeight, scrollHeight);

  debouncedSave.value(e);
};

watch(
  assetId,
  newId => {
    noteIdRef.value = null;
    if (!newId) return;
    debouncedSave.value.cancel();
    currentNote.value = assetNote.value;
    isEditing.value = false;
  },
  { immediate: true }
);

watch(
  () => assetNote.value,
  newNote => {
    if (isEditing.value) return;
    currentNote.value = newNote;
  }
);

onUpdated(() => {
  if (!isInitialized.value) {
    const offsetHeight = textareaRef.value?.offsetHeight;
    const scrollHeight = textareaRef.value?.scrollHeight;

    changeInputHeight(textareaRef.value, offsetHeight, scrollHeight);
    isInitialized.value = true;
  }
});

const handleSubmit = e => {
  e.preventDefault();
  handleSave();
};

watchEffect(() => {
  if ((currentNote.value ?? '') !== (assetNote.value ?? '')) {
    emits('is-notes-dirty', true);
    return;
  }
  emits('is-notes-dirty', false);
});
</script>

<template>
  <component
    :is="!isAutosave ? 'form' : 'div'"
    class="asset-notes"
    v-on="!isAutosave ? { submit: handleSubmit } : {}"
  >
    <label :for="id" :class="{ 'u-a11y-only': !label }">{{ label }}</label>
    <div
      v-if="!isAutosave && !isEditing && assetNote !== null"
      class="asset-notes__display"
    >
      {{ currentNote }}
    </div>
    <textarea
      v-else
      :id="id"
      ref="textareaRef"
      v-model="currentNote"
      rows="2"
      :disabled="isLoading && !textareaHasFocus"
      placeholder="add a note"
      @focus="textareaHasFocus = true"
      @blur="textareaHasFocus = false"
      v-on="isAutosave ? { change: handleSave, input: handleInput } : {}"
    />
    <Transition name="fade">
      <p
        v-if="isHelperActive"
        class="asset-notes__helper"
        :class="{
          'asset-notes__helper--saving': isLoading,
          'asset-notes__helper--error': isError,
        }"
        :aria-hidden="isHelperActive"
      >
        <SoonaIcon :name="helperIcon" size="small" />
        <span>{{ helperCopy }}</span>
      </p>
    </Transition>
    <SoonaError v-if="!isAutosave && isError" class="asset-notes__error">
      our tiny robots are having problems. unable to save.
    </SoonaError>
    <SoonaButton
      v-if="!isAutosave && !isEditing && assetNote !== null"
      class="asset-notes__button"
      size="medium"
      variation="secondary-gray"
      @on-click="isEditing = true"
    >
      <SoonaIcon name="pen-square" /> edit
    </SoonaButton>
    <SoonaButton
      v-else-if="!isAutosave"
      class="asset-notes__button"
      type="submit"
      size="medium"
      :disabled="isLoading || (currentNote ?? '') === ''"
      :loading="isLoading"
    >
      save
    </SoonaButton>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.asset-notes {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    @include variables_fonts.u-label--heavy;

    display: block;
    margin-bottom: 0.25rem;
  }

  &__display {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  textarea {
    @include variables_fonts.u-body--regular;

    display: block;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    min-height: 2rem;
    max-height: 25rem;
    padding: 0.25rem 0.5rem;
    resize: vertical;
    width: 100%;
  }

  &__helper {
    @include variables_fonts.u-label--regular;

    align-items: center;
    display: flex;
    gap: 0.25rem;

    svg {
      color: variables.$avo-toast-40;
    }

    &--saving {
      svg {
        opacity: 0.4;
      }
    }

    &--error {
      svg {
        color: variables.$roses-60;
      }
    }
  }

  &__error {
    margin: 0.5rem 0 0;
  }

  &__button {
    margin: 0.75rem 0 0 auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
