import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';

export function useCreateBagSalesTax({ accountId, orderTotal }, queryOptions) {
  return useQuery({
    queryKey: queryKeys.bagSalesTax({
      accountId,
      orderTotal,
    }),
    queryFn: async ({ signal }) => {
      const response = await http.get(`/sales_tax/bag/`, { signal });

      return response.data;
    },
    ...queryOptions,
  });
}
